require('./teams/_');

require("./app-logo.png");
require("./nissan-logo.png");
require("./cars.png");
require("./nim-logo.png");
require("./stadium.jpg");
require("./champions-logo.png");
require("./favicon.png");
require("./nissan-qashqai.png");
require("./nissan-leaf.png");
require("./nissan-micra.png");
require("./logo-playstation.png");
require("./playstation-console.png");
require("./playstation-glasses.png");
require("./champions-league-logo.jpg");
