export function vin() {
    $('div.have-vin').each(function () {
        let $this = $(this);
        let $yes = $this.find("[value='yes']");
        let $no = $this.find("[value='no']");
        let $input = $this.find("#" + $this.data("input"));
        let $box = $this.find('.entry-page__frame-box');

        $yes.on('change', function () {
            $box.removeClass('entry-page__frame-box--hidden');
            $input.attr('disabled',null);

        });

        $no.on('change', function () {
            $box.addClass('entry-page__frame-box--hidden');
            $input.attr('disabled','disabled');
            $input.val(null);
        })
    })
};