export function validate_form_team() {
    const trans = JSON.parse(document.trans);
    $('.valid-form-teams').each(function () {
        let $this = $(this);
        let $prototypeError = '<span class="invalid-feedback d-block"><span class="d-block"><span class="form-error-icon badge badge-danger text-uppercase">'+trans.error+'</span> <span class="form-error-message">'+trans.repeatTeam+'</span></span></span>';

        let util = {
            repeat: function () {
                let _values = [];
                let _repeat = false;
                $this.find('select').each(function () {
                    let _value = $(this).val();
                    if (_values.indexOf(_value) > -1) {
                        _repeat = true;
                    } else {
                        _values.push(_value);
                    }
                });
                return _repeat;
            },
            disabled: function () {
                $this.find('button').addClass('disabled');
            },
            enabled: function () {
                $this.find('button').removeClass('disabled');
            },
            repeatValue: function (val) {
                let _repeat = 0;
                $this.find('select').each(function () {
                    let _value = $(this).val();
                    if (val === _value) {
                        _repeat++;
                    }
                });
                return _repeat;
            },
            addError:function (element) {
                let elementError = util.getElementError(element);
                elementError.append($prototypeError);
            },
            removeError:function (element) {
                let elementError = util.getElementError(element);
                elementError.html(null);
            },
            getElementError:function (element) {
                return $('#'+element.attr('id')+'_error');
            }
        }

        let checkButton = function () {
            if (util.repeat())
                util.disabled();
            else
                util.enabled();
        }

        let checkAllRepeat = function () {
            $this.find('select').each(function () {
                let _element = $(this);
                util.removeError(_element);
                if(util.repeatValue(_element.val())>1){
                    util.addError(_element);
                }
            });
        }

        let checkSelect = function () {
            checkButton();
            checkAllRepeat();
        }

        checkButton();
        $this.find('select').on('change', checkSelect);
    });
};