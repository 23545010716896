'use strict';
import $ from 'jquery';
import 'bootstrap';
import 'blueimp-file-upload/js/jquery.fileupload';

global.$ = global.jQuery = $;
global.Noty = require("noty");
import {app_flashes} from "./utils/app.flashes";
import {app_sprite} from "./utils/sprite";
import {vin} from "./utils/vin";
import {validate_form_team} from "./utils/valid.form.teams";
import {validate_score_input} from "./utils/score-input-validator";


$(function () {
    app_flashes();
    app_sprite();
    vin();
    validate_form_team();
    validate_score_input();
});
